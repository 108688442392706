<template>
  <div class="content-shopping-car">
    <div class="content-shopping-car-itens container">
      <div class="content-shopping-car-itens-header">
        <h1>{{ $t('shopping_cart.shopping_cart') }}</h1>
      </div>
      <div class="content-shopping-car-itens-body">
        <div class="row">
          <div :class="[cartProducts.itens && cartProducts.itens.length !== 0 ? 'col-lg-8' : 'col-lg-12']">
            <template v-if="cartProducts.itens && cartProducts.itens.length == 0">
              <h2 class="not-products">
                {{ $t('shopping_cart.text_shopping_cart') }}
                <span> 
                  <img class="img-fluid" src="@/assets/img/shopping-cart/image-cart.png" alt="image cart" title="image cart"/>
                </span>
              </h2>
            </template>
            <template v-else>
              <!-- Component Cart Products -->
              <cart-products />
              <!----------------------------->
            </template>
            <div class="content-elements-btn">
              <router-link
                tag="a"
                class="content-elements-btn-continue"
                :to="{ name: 'Home' }"
              >
                <img
                  class="img-fluid"
                  src="@/assets/img/arrow-left.png"
                  alt="arrow left"
                  title="arrow left"
                />
                {{ $t('btns_default.continue_shopping') }}
              </router-link>
              <!-- <button tag="a" class="content-elements-btn-clear">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.055"
                  height="22.92"
                  viewBox="0 0 20.055 22.92"
                >
                  <path
                    d="M19.7,2.865H15.041L13.537.859A2.149,2.149,0,0,0,11.818,0H8.237A2.149,2.149,0,0,0,6.518.859l-1.5,2.005H.358A.358.358,0,0,0,0,3.223v.716A.358.358,0,0,0,.358,4.3H1.2L2.69,20.964A2.149,2.149,0,0,0,4.83,22.92H15.225a2.149,2.149,0,0,0,2.14-1.956L18.851,4.3H19.7a.358.358,0,0,0,.358-.358V3.223A.358.358,0,0,0,19.7,2.865ZM7.664,1.719a.721.721,0,0,1,.573-.286h3.581a.721.721,0,0,1,.573.286l.859,1.146H6.8Zm8.273,19.115a.712.712,0,0,1-.712.654H4.83a.712.712,0,0,1-.712-.654L2.641,4.3H17.414Z"
                    fill="#666"
                  />
                </svg>
                Clear shopping cart
              </button> -->
            </div>
          </div>
          <template v-if="cartProducts.itens && cartProducts.itens.length !== 0">
            <div class="col-lg-4">
              <cart-resume />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CartProducts from "@/views/Cart/views/CartProducts.vue";
import CartResume from "@/views/Cart/views/CartResume.vue";

export default {
  data() {
    return {
      productQuantity: 1,
    };
  },
  components: {
    CartProducts,
    CartResume,
  },
  computed: {
    ...mapGetters("Cart", ["cartProducts"]),
  },
};
</script>

<style lang="scss">
.content-shopping-car {
  padding: 80px 0px;
  @media (max-width: 991px) {
    padding: 40px 0px;
  }
  &-itens {
    &-header {
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;
      h1 {
        font-size: 35px;
        font-family: "Font Bold";
        color: var(--main-color5);
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
    &-body {
      padding-top: 50px;
      @media (max-width: 991px) {
        padding-top: 30px;
      }
      .content-elements {
        padding: 30px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 30px;
        border-bottom: 1px solid #ebebeb;
        @media (max-width: 576px) {
          flex-direction: column;
        }
        &-div {
          display: flex;
          align-items: center;
          text-align: left;
          @media (max-width: 991px) {
            &:first-child {
              margin-bottom: 20px;
            }
          }
          .btn-clear {
            margin-right: 30px;
            background: 0;
            border: 0;
            padding: 0;
            transition: 500ms;
            &:hover,
            &:focus {
              cursor: pointer;
              opacity: 0.8;
            }
          }
          &-image {
            margin-right: 25px;
            img {
              max-width: 150px;
            }
          }
          &-text {
            padding-right: 20px;
            .name {
              font-size: 25px;
              font-family: "Font Light";
              color: var(--main-color4);
              margin-bottom: 5px;
              @media (max-width: 1199px) {
                font-size: 20px;
              }
            }
            .size {
              font-size: 16px;
              font-family: "Font Light";
              color: var(--main-color4);
              margin-bottom: 0;
              span {
                font-family: "Font Bold";
              }
            }
          }
          .price-product {
            font-size: 25px;
            font-family: "Font Light";
            color: var(--main-color4);
            margin-bottom: 0;
            margin-right: 30px;
            @media (max-width: 1199px) {
              font-size: 20px;
            }
          }
          .price-total {
            font-size: 25px;
            font-family: "Font Bold";
            color: var(--main-color4);
            margin-bottom: 0;
            @media (max-width: 1199px) {
              font-size: 20px;
            }
          }
          .number {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 140px;
            height: 55px;
            background: #f8f8f8;
            margin-right: 30px;
            padding: 0px 10px;
            @media (max-width: 1199px) {
              width: 100px;
            }
            .minus,
            .plus {
              border: 0;
              background: 0;
              padding: 0;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            input {
              height: 20px;
              width: 50px;
              text-align: center;
              font-size: 25px;
              font-family: "Font Bold";
              color: var(--main-color4);
              border-radius: 3px;
              background: #f8f8f8;
              border: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0;
              margin: 0px 10px;
              outline: none;
              pointer-events: none;
              @media (max-width: 1199px) {
                width: 30px;
              }
            }
          }
        }
        &-btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 30px;
          &-continue,
          &-clear {
            font-size: 16px;
            font-family: "Font Bold";
            color: var(--main-color4);
            margin-bottom: 0;
            display: flex;
            align-items: center;
            background: 0;
            border: 0;
            transition: 500ms;
            text-decoration: none;
            svg,
            img {
              margin-right: 10px;
            }
            &:hover,
            &:focus {
              cursor: pointer;
              opacity: 0.8;
            }
            @media (max-width: 991px) {
              font-size: 12px;
              svg,
              img {
                margin-right: 5px;
              }
            }
          }
        }
      }
      .content-resume {
        padding: 40px 30px;
        background: var(--main-color4);
        position: sticky;
        top: 120px;
        @media (min-width: 992px) and (max-height: 800px) {
		      top: 100px;
	      }
        @media (max-width: 991px) {
          margin-top: 30px;
          position: relative;
          top: initial;
        }
        &-header {
          padding-bottom: 20px;
          border-bottom: 1px solid var(--main-color);
          text-align: left;
          h2 {
            font-size: 25px;
            font-family: "Font Bold";
            color: var(--main-color);
            margin-bottom: 0;
            text-transform: uppercase;
          }
        }
        &-body {
          padding-top: 30px;
          &-total {
            font-size: 42px;
            font-family: "Font Bold";
            color: var(--main-color);
            margin-bottom: 0;
            text-align: left;
            @media (max-width: 1199px) {
              font-size: 32px;
            }
            span {
              display: block;
            }
          }
          &-btn {
            height: 55px;
            width: 100%;
            max-width: 310px;
            background: var(--main-color6);
            border: 1px solid var(--main-color6);
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            font-size: 20px;
            font-family: "Font Bold";
            color: var(--main-color);
            text-transform: uppercase;
            margin-top: 30px;
            transition: 500ms;
            &:hover,
            &:focus {
              background: var(--main-color);
              color: var(--main-color6);
            }
            @media (min-width: 992px) and (max-width: 1199px) {
              font-size: 16px;
              height: 50px;
            }
            @media (max-width: 359px) {
              font-size: 16px;
              height: 45px;
            }
          }
        }
      }
    }
  }
}
.not-products {
  width: 100%;
  font-size: 32px;
  font-family: "Font Bold";
  color: var(--main-color4);
  margin-bottom: 0;
  text-align: center;
  padding: 50px 0px;
  @media (max-width: 576px) {
    font-size: 20px;
  }
  span {
    display: block;
    font-size: 80px;
    margin-top: 20px;
    @media (max-width: 576px) {
      font-size: 60px;
      img {
        max-width: 70px;
      }
    }
  }
}
</style>