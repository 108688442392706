<template>
  <div>
    <template v-if="!cartLoading">
      <div v-for="(item, itemIndex) in cartProducts.itens" :key="itemIndex" class="content-elements">
        <div class="content-elements-div">
          <button @click="removeItemCart([item.id, item])" class="btn-clear">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.055"
              height="22.92"
              viewBox="0 0 20.055 22.92"
            >
              <path
                d="M19.7,2.865H15.041L13.537.859A2.149,2.149,0,0,0,11.818,0H8.237A2.149,2.149,0,0,0,6.518.859l-1.5,2.005H.358A.358.358,0,0,0,0,3.223v.716A.358.358,0,0,0,.358,4.3H1.2L2.69,20.964A2.149,2.149,0,0,0,4.83,22.92H15.225a2.149,2.149,0,0,0,2.14-1.956L18.851,4.3H19.7a.358.358,0,0,0,.358-.358V3.223A.358.358,0,0,0,19.7,2.865ZM7.664,1.719a.721.721,0,0,1,.573-.286h3.581a.721.721,0,0,1,.573.286l.859,1.146H6.8Zm8.273,19.115a.712.712,0,0,1-.712.654H4.83a.712.712,0,0,1-.712-.654L2.641,4.3H17.414Z"
                fill="#666"
              />
            </svg>
          </button>
          <div class="content-elements-div-image">
            <img
              class="img-fluid"
              :src="item.product.attachs[0]"
              :alt="item.product.name"
              :title="item.product.name"
            />
          </div>
          <div class="content-elements-div-text">
            <h2 class="name">{{item.product.name}}</h2>
            <!-- <h3 class="size">size: <span> {{item.product.size}} ml</span></h3> -->
          </div>
        </div>
        <div class="content-elements-div">
          <p class="price-product">€{{ item.price_unit }}</p>
          <div class="number">
            <button class="minus" @click="btnMinus(item)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="2"
                viewBox="0 0 16 2"
              >
                <rect width="16" height="2" fill="#d2d2d2" />
              </svg>
            </button>
            <input type="text" v-model="item.amount" />
            <button class="plus" @click="btnPlus(item)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <g transform="translate(-1333 -569)">
                  <rect
                    width="16"
                    height="2"
                    transform="translate(1333 576)"
                    fill="#d2d2d2"
                  />
                  <rect
                    width="16"
                    height="2"
                    transform="translate(1342 569) rotate(90)"
                    fill="#d2d2d2"
                  />
                </g>
              </svg>
            </button>
          </div>
          <p class="price-total">€{{ item.price_total }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("Cart", [
      "cartOpen",
      "cartProducts",
      "cartProductsTotal",
      "cartLoading",
    ]),
  },
  methods: {
    ...mapActions("Cart", [
      "openCartFloating",
      "removeItemCart",
      "updateCartItem",
    ]),
    btnMinus(item) {
      if (item.amount !== 1){
        item.amount--;
      }
      let params = {
        id: item.id,
        amount: item.amount,
      }
      this.updateCartItem(params);
    },
    btnPlus(item) {
      item.amount++;

      let params = {
        id: item.id,
        amount: item.amount,
      }
      this.updateCartItem(params);
    },
  },
};
</script>

<style>
</style>