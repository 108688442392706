<template>
  <div class="content-resume">
    <div class="content-resume-header">
      <h2>{{ $t('shopping_cart.resume') }}</h2>
    </div>
    <div class="content-resume-body">
      <h3 class="content-resume-body-total">
        Total: <span>€ {{ cartProducts.price_itens }}</span>
      </h3>
      <router-link :to="{name: 'cart-checkout'}" class="content-resume-body-btn">{{ $t('shopping_cart.proceed_to_checkout') }}</router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Cart", ["cartProducts"]),
  },
};
</script>

<style>
</style>